/* =============================================================================
   TYPOGRAPHY (settings/_typography.scss)
   ========================================================================== */


/* @fontface imports
   ========================================================================== */

@include font-face("Roboto", "../../assets/fonts/roboto/roboto-300-webfont", 300, normal, "Roboto");
@include font-face("Roboto", "../../assets/fonts/roboto/roboto-300italic-webfont", 300, italic, "Roboto");
@include font-face("Roboto", "../../assets/fonts/roboto/roboto-400-webfont", 400, normal, "Roboto");
@include font-face("Roboto", "../../assets/fonts/roboto/roboto-400italic-webfont", 400, italic, "Roboto");
@include font-face("Roboto", "../../assets/fonts/roboto/roboto-600-webfont", 600, normal, "Roboto");
@include font-face("Roboto", "../../assets/fonts/roboto/roboto-600italic-webfont", 600, italic, "Roboto");


/* Font Families
   ========================================================================== */

$font-family-base: "Roboto", "Droid Sans", Arial, sans-serif !default;


/* Weights
   ========================================================================== */

$font-weight-light: 300 !default;
$font-weight-base: 400 !default;
$font-weight-accent: 600 !default;


/* Line Heights
   ========================================================================== */

$line-height-base: 1.6 !default;
$line-height-medium: 1.25 !default;
$line-height-large: 1.1 !default;


/* Font Sizes
   ========================================================================== */

$font-size-base: 16px !default;

$font-sizes-headings: (
	h1: rem(64px),
	h2: rem(44px),
	h3: rem(36px),
	h4: rem(28px),
	h5: rem(24px),
	h6: rem(20px),
) !default;

$font-sizes-other: (
	base: rem(18px),
	01: rem(10px),
	02: rem(12px),
	03: rem(14px),
	04: rem(16px),
	05: rem(18px),
	06: rem(20px),
	07: rem(22px)
) !default;

$font-sizes: map-merge(
	$font-sizes-headings, $font-sizes-other
) !default;
