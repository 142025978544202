
@include input-text {
	display: block;
	margin: 0;
	width: 100%;
	min-height: $form-field-height;
	max-height: $form-field-height;
	padding-top: ($form-field-height - $form-text-line-height - (2 * $form-border-width)) / 2;
	padding-bottom: ($form-field-height - $form-text-line-height - (2 * $form-border-width)) / 2;
	line-height: $form-text-line-height;
	font-size: $form-text-size;
	font-weight: $form-text-weight;
	transition: all $transition-speed;

	&::placeholder {
		font-weight: $form-placeholder-weight;
	}
}

select {
	&[multiple] {
		background: $form-fill-background;
		min-height: inherit;
		max-height: none;
		height: $form-textarea-height;
	}
	option {
		color: $color-accent;
	}
}

textarea {
	min-height: inherit;
	max-height: none;
	height: $form-textarea-height;
	line-height: $line-height-base;
	padding-top: ($form-field-height - ($form-text-size * $line-height-base)) / 2;
	padding-bottom: ($form-field-height - (2 * $form-border-width) - ($form-text-size * $line-height-base)) / 2;
}

label {
	display: block;
	margin-bottom: $spacer;
	text-align: left;
	font-size: $form-label-size;
	font-weight: $form-label-weight;
	transition: all $transition-speed;
	color: $form-label-color;
	line-height: $line-height-base;

	.c-color--invert & {
		color: $form-label-color-invert;
	}
}

legend {
	display: block;
	margin-bottom: $spacer;
	font-size: $form-label-size;
	font-weight: $font-weight-accent;
	line-height: $line-height-base;
	color: $form-label-color;
}

.c-form-element {
	margin-bottom: $spacer-base;

	.c-form-element__field {
		position: relative;
		margin-bottom: $spacer;
	}
	.c-form-element__loader {
		position: absolute;
		bottom: - rem(1px);
		width: 100%;
		height: rem(3px);
		background: $form-fill-background;
		overflow: hidden;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: rem(3px);
			background: $form-fill-border-color-selected;
			animation-name: loadingFormField;
			animation-duration: 1.5s;
			animation-iteration-count: infinite;
		}
	}
	.c-note {
		font-size: $form-help-text-size;
		font-weight: $form-help-weight;
	}
}



/* Form icons
   ========================================================================== */

.c-form-element__icon {
	position: absolute;
	top: 0;
	width: $form-icon-size;
	height: $form-field-height;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: $form-icon-size;
	transition: all $transition-speed;
	pointer-events: none;

	img,
	svg {
		width: $form-icon-size;
		height: $form-icon-size;
		margin: 0;

		&.o-svg-large {
			height: $form-icon-size * 1.5;
			width: $form-icon-size * 1.5;
		}
		&.o-svg-larger {
			height: $form-icon-size * 1.25;
			width: $form-icon-size * 1.25;
		}
		&.o-svg-smaller {
			height: $form-icon-size * 0.75;
			width: $form-icon-size * 0.75;
		}
		&.o-svg-small {
			height: $form-icon-size * 0.5;
			width: $form-icon-size * 0.5;
		}
	}
}


/* Form Select
   ========================================================================== */

.c-form-element--select {
	.c-form-element__field {
		&:before {
			content: '';
			position: absolute;
			right: $form-padding-horizontal;
			top: 50%;
			width: $form-select-icon-size;
			height: $form-field-height;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			transform: translateY(-50%);
			opacity: $form-select-icon-opacity;
			pointer-events: none;
		}
		select {
			margin: 0;
			padding-top: ($form-field-height - $form-text-line-height - (2 * $form-border-width)) / 2;
			padding-bottom: ($form-field-height - $form-text-line-height - (2 * $form-border-width)) / 2;
			padding-right: $form-select-icon-size + $form-padding-horizontal + rem(4px);
		}
	}
}



/* Form Choices
   ========================================================================== */

.c-form-element--checkbox,
.c-form-element--checkbox-indeterminate,
.c-form-element--radio,
.c-form-element--toggle {
	position: relative;

	.c-form-label {
		margin-bottom: $spacer-base-small
	}

	input {
		position: absolute;
		z-index: -1;
		opacity: 0;

		+ label {
			position: relative;
			padding-left: $form-choice-width + $form-padding-horizontal;
			min-height: $form-text-size * $line-height-base;
			font-size: $form-text-size;
			font-weight: $form-choice-weight;
			color: $form-choice-color;
			cursor: pointer;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: (($form-text-size * $line-height-base) - $form-choice-width) / 2;
				left: 0;
				width: $form-choice-width;
				height: $form-choice-width;
				line-height: 1;
				transition: all $transition-speed;
			}
			&:after {
				content: '';
				display: none;
				position: absolute;
				top: (($form-text-size * $line-height-base)) / 2;
				left: ($form-choice-width) / 2;
				width: $form-radio-dot-size;
				height: $form-radio-dot-size;
				border-radius: 50%;
				line-height: 1;
				transform: translate(-50%,-50%);
				transition: all $transition-speed;
			}

			&:hover {
				color: $form-choice-color-hover;
			}
		}
		&:focus {
			+ label {
				color: $form-choice-color-hover;
			}
		}
		&:checked {
			+ label {
				color: $form-choice-color-selected;
			}
		}

		.c-color--invert & {
			+ label {
				color: $form-choice-color-invert;

				&:hover {
					color: $form-choice-color-invert-hover;
				}
			}
			&:focus {
				+ label {
					color: $form-choice-color-invert-hover;
				}
			}
			&:checked {
				+ label {
					color: $form-choice-color-invert-selected;
				}
			}
		}
	}

	&--basic {
		input {
			+ label {
				padding-left: $form-choice-width;
			}
		}
	}
	&--reverse {
		input {
			left: auto;
			right: 1px;

			+ label {
				padding-left: 0;
				padding-right: $form-choice-width + $form-padding-horizontal;

				&:before {
					left: auto;
					right: 0;
				}
			}
		}
	}
}
.c-form-element--radio {
	input {
		+ label {
			&:before {
				border-radius: 50%;
				background-color: $form-radio-background;
				background-position: 50% 0%;
				background-repeat: no-repeat;
				border: $form-choice-border-width solid $form-radio-border-color;
			}
			&:after {
				display: block;
				background-color: transparent;
			}

			&:hover {
				&:before {
					border-color: $form-radio-border-color-hover;
				}
			}
		}
		&:focus {
			+ label {
				&:before {
					border-color: $form-radio-border-color-hover;
				}
			}
		}
		&:checked {
			+ label {
				&:before {
					background-color: $form-radio-background-selected;
					border-color: $form-radio-border-color-selected;
				}
				&:after {
					background-color: $form-radio-dot-background;
				}
			}
		}

		.c-color--invert & {
			+ label {
				&:before {
					background-color: $form-radio-background-invert;
					border-color: $form-radio-border-color-invert;
				}
				&:hover {
					&:before {
						border-color: $form-radio-border-color-invert-hover;
					}
				}
			}
			&:focus {
				+ label {
					&:before {
						border-color: $form-radio-border-color-invert-hover;
					}
				}
			}
			&:checked {
				+ label {
					&:before {
						background-color: $form-radio-background-invert-selected;
						border-color: $form-radio-border-color-invert-selected;
					}
					&:after {
						background-color: $form-radio-dot-background-invert;
					}
				}
			}
		}
	}

	&--reverse {
		input {
			+ label {
				&:after {
					left: auto;
					right: ($form-choice-width) / 2;
					transform: translate(50%,-50%);
				}
			}
		}
	}
}
.c-form-element--toggle {
	input {
		+ label {
			padding-left: $form-toggle-width + $form-padding-horizontal;

			&:before {
				top: (($form-text-size * $line-height-base) - $form-choice-width) / 2;
				width: $form-toggle-width;
				height: $form-choice-width;
				border-radius: $form-choice-width / 2;
				background-color: $form-toggle-background;
				border: $form-choice-border-width solid $form-toggle-border-color;
			}
			&:after {
				left: ($form-choice-width) / 2;
				width: $form-toggle-dot-size;
				height: $form-toggle-dot-size;
				display: block;
				background-color: $form-toggle-dot-background;
			}

			&:hover {
				&:before {
					background-color: $form-toggle-background-hover;
					border-color: $form-toggle-border-color-hover;
				}
			}
		}
		&:focus {
			+ label {
				&:before {
					border-color: $form-toggle-border-color-hover;
				}
			}
		}
		&:checked {
			+ label {
				&:before {
					background-color: $form-toggle-background-selected;
					border-color: $form-toggle-border-color-selected;
				}
				&:after {
					left: $form-toggle-width - ($form-choice-width / 2);
					background-color: $form-toggle-dot-background-selected;
				}
			}
		}

		.c-color--invert & {
			+ label {
				&:before {
					background-color: $form-toggle-background-invert;
					border-color: $form-toggle-border-color-invert;
				}
				&:after {
					background-color: $form-toggle-dot-background-invert;
				}
				&:hover {
					&:before {
						background-color: $form-toggle-background-invert-hover;
						border-color: $form-toggle-border-color-invert-hover;
					}
				}
			}
			&:focus {
				+ label {
					&:before {
						border-color: $form-toggle-border-color-invert-hover;
					}
				}
			}
			&:checked {
				+ label {
					&:before {
						background-color: $form-toggle-background-invert-selected;
						border-color: $form-toggle-border-color-invert-selected;
					}
					&:after {
						background-color: $form-toggle-dot-background-invert-selected;
					}
				}
			}
		}
	}

	&--reverse {
		input {
			+ label {
				padding-left: 0;
				padding-right: $form-toggle-width + $form-padding-horizontal;

				&:after {
					left: auto;
					right: $form-toggle-width - ($form-choice-width / 2);
					transform: translate(50%,-50%);
				}
			}
			&:checked {
				+ label {
					&:after {
						left: auto;
						right: ($form-choice-width / 2);
					}
				}
			}
		}
	}
}
.c-form-element--checkbox,
.c-form-element--checkbox-indeterminate {
	input {
		+ label {
			&:before {
				border-radius: $form-check-border-radius;
				background-color: $form-check-background;
				background-position: 50% 0%;
				background-repeat: no-repeat;
				background-size: $form-check-icon-size;
				border: $form-choice-border-width solid $form-check-border-color;
			}

			&:hover {
				&:before {
					border-color: $form-check-border-color-hover;
				}
			}
		}
		&:focus {
			+ label {
				&:before {
					border-color: $form-check-border-color-hover;
				}
			}
		}
		&:checked {
			+ label {
				&:before {
					background-image: $form-check-icon-path;
					background-position: 50% 50%;
					background-color: $form-check-background-selected;
					border-color: $form-check-border-color-selected;
				}
			}
		}

		.c-color--invert & {
			+ label {
				&:before {
					background-color: $form-check-background-invert;
					border-color: $form-check-border-color-invert;
				}
				&:hover {
					&:before {
						border-color: $form-check-border-color-invert-hover;
					}
				}
			}
			&:focus {
				+ label {
					&:before {
						border-color: $form-check-border-color-invert-hover;
					}
				}
			}
			&:checked {
				+ label {
					&:before {
						background-image: $form-check-icon-path-invert;
						background-color: $form-check-background-invert-selected;
						border-color: $form-check-border-color-invert-selected;
					}
				}
			}
		}
	}
}
.c-form-element--checkbox-indeterminate {
	input {
		&:checked {
			+ label {
				&:before {
					background-image: $form-check-indeterminate-icon-path;
				}
			}
		}

		.c-color--invert & {
			&:checked {
				+ label {
					&:before {
						background-image: $form-check-indeterminate-icon-path-invert;
					}
				}
			}
		}
	}
}

/* Form elements styles
   ========================================================================== */

.c-form-element--style-line {
	@include input-text {
		padding-left: 0;
		padding-right: 0;
		color: $form-text-color;
		background-color: $form-background;
		border-radius: $form-field-border-radius;
		border-bottom: $form-border-width solid $form-border-color;
		box-shadow: $form-shadow;

		&:hover {
			background-color: $form-background-hover;
			border-color: $form-border-color-hover;
			box-shadow: $form-shadow-hover;
		}
		&:focus,
		&:active {
			background-color: $form-background-selected;
			border-color: $form-border-color-selected;
			box-shadow: $form-shadow-active;
		}

		&::placeholder {
			color: $form-placeholder-color;
		}

		.c-color--invert & {
			color: $form-text-color-invert;
			background-color: $form-background-invert;
			border-color: $form-border-color-invert;
			box-shadow: $form-shadow-invert;

			&:hover {
				background-color: $form-background-invert-hover;
				border-color: $form-border-color-invert-hover;
				box-shadow: $form-shadow-invert-hover;
			}
			&:focus,
			&:active {
				background-color: $form-background-invert-selected;
				border-color: $form-border-color-invert-selected;
				box-shadow: $form-shadow-invert-active;
			}

			&::placeholder {
				color: $form-placeholder-color-invert;
			}
		}
	}
	.c-form-element__icon {
		left: 0;
		color: $form-icon-color;

		~ input,
		~ select,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap select,
		~ .fl-wrap textarea {
			padding-left: $form-icon-size + $form-padding-horizontal;
		}

		.c-color--invert & {
			color: $form-icon-color-invert;
		}
	}
	&.c-form-element--icon-last .c-form-element__icon,
	.c-form-element__icon:nth-child(2) {
		left: auto;
		right: 0;

		~ input,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap textarea {
			padding-right: $form-icon-size + $form-padding-horizontal;
		}
	}
	&.c-form-element--icon-last .c-form-element__icon {
		~ input,
		~ select,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap select,
		~ .fl-wrap textarea {
			padding-left: 0;
		}
	}
	&.c-form-element--select {
		.c-form-element__field {
			&:before {
				background-image: $form-select-icon-path;
			}
		}
		.c-color--invert & {
			.c-form-element__field {
				&:before {
					background-image: $form-select-icon-path-invert;
				}
			}
		}

		&.c-form-element--icon-last .c-form-element__icon,
		.c-form-element__icon:nth-child(2) {
			right: $form-padding-horizontal + $form-select-icon-size + $form-padding-horizontal + rem(4px);

			~ select,
			~ .fl-wrap select {
				padding-right: $form-padding-horizontal + $form-icon-size + $form-padding-horizontal + $form-select-icon-size + $form-padding-horizontal + rem(4px);
			}
		}
	}
}
.c-form-element--style-fill {
	@include input-text {
		padding-left: $form-padding-horizontal;
		padding-right: $form-padding-horizontal;
		color: $form-fill-text-color;
		background-color: $form-fill-background;
		border-radius: $form-fill-field-border-radius;
		border: $form-border-width solid $form-fill-border-color;
		border-top-color: transparent !important;
		border-left-width: 0;
		border-right-width: 0;
		box-shadow: $form-fill-shadow;

		&:hover {
			background-color: $form-fill-background-hover;
			border-color: $form-fill-border-color-hover;
			box-shadow: $form-fill-shadow-hover;
		}
		&:focus,
		&:active {
			background-color: $form-fill-background-selected;
			border-color: $form-fill-border-color-selected;
			box-shadow: $form-fill-shadow-active;
		}

		&::placeholder {
			color: $form-fill-placeholder-color;
		}

		.c-color--invert & {
			color: $form-fill-text-color-invert;
			background-color: $form-fill-background-invert;
			border-color: $form-fill-border-color-invert;
			box-shadow: $form-fill-shadow-invert;

			&:hover {
				background-color: $form-fill-background-invert-hover;
				border-color: $form-fill-border-color-invert-hover;
				box-shadow: $form-fill-shadow-invert-hover;
			}
			&:focus,
			&:active {
				background-color: $form-fill-background-invert-selected;
				border-color: $form-fill-border-color-invert-selected;
				box-shadow: $form-fill-shadow-invert-active;
			}

			&::placeholder {
				color: $form-fill-placeholder-color-invert;
			}
		}
	}
	textarea {
		padding-top: ($form-field-height - (2 * $form-border-width) - ($form-text-size * $line-height-base)) / 2;
	}
	.c-form-element__icon {
		left: $form-padding-horizontal;
		color: $form-fill-icon-color;

		~ input,
		~ select,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap select,
		~ .fl-wrap textarea {
			padding-left: $form-padding-horizontal + $form-icon-size + $form-padding-horizontal;
		}

		.c-color--invert & {
			color: $form-fill-icon-color-invert;
		}
	}
	&.c-form-element--icon-last .c-form-element__icon,
	.c-form-element__icon:nth-child(2) {
		left: auto;
		right: $form-padding-horizontal;

		~ input,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap textarea {
			padding-right: $form-padding-horizontal + $form-icon-size + $form-padding-horizontal;
		}
	}
	&.c-form-element--icon-last .c-form-element__icon {
		~ input,
		~ select,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap select,
		~ .fl-wrap textarea {
			padding-left: $form-padding-horizontal;
		}
	}
	&.c-form-element--select {
		.c-form-element__field {
			&:before {
				background-image: $form-select-icon-path;
			}
		}
		.c-color--invert & {
			.c-form-element__field {
				&:before {
					background-image: $form-select-icon-path;
				}
			}
		}

		&.c-form-element--icon-last .c-form-element__icon,
		.c-form-element__icon:nth-child(2) {
			right: $form-padding-horizontal + $form-select-icon-size + $form-padding-horizontal + rem(4px);

			~ select,
			~ .fl-wrap select {
				padding-right: $form-padding-horizontal + $form-icon-size + $form-padding-horizontal + $form-select-icon-size + $form-padding-horizontal + rem(4px);
			}
		}
	}
}



/* Form elements sizes
   ========================================================================== */

@mixin form-element--short {
	@include input-text {
		min-height: $form-field-height-small;
		max-height: $form-field-height-small;
		padding-top: ($form-field-height-small - $form-text-line-height - (2 * $form-border-width)) / 2;
		padding-bottom: ($form-field-height-small - $form-text-line-height - (2 * $form-border-width)) / 2;
	}
	select {
		&[multiple] {
			height: $form-textarea-height-small;
		}
	}
	textarea {
		min-height: inherit;
		max-height: none;
		height: $form-textarea-height-small;
		padding-top: ($form-field-height-small - ($form-text-size * $line-height-base)) / 2;
		padding-bottom: ($form-field-height-small - (2 * $form-border-width) - ($form-text-size * $line-height-base)) / 2;
	}
	.c-form-element__icon {
		height: $form-field-height-small;

		~ input,
		~ select,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap select,
		~ .fl-wrap textarea {
			padding-left: $form-icon-size + $form-padding-horizontal-small;
		}
	}
	&.c-form-element--icon-last .c-form-element__icon,
	.c-form-element__icon:nth-child(2) {
		~ input,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap textarea {
			padding-right: $form-icon-size + $form-padding-horizontal-small;
		}
	}
	&.c-form-element--checkbox,
	&.c-form-element--checkbox-indeterminate,
	&.c-form-element--radio,
	&.c-form-element--toggle {
		input {
			+ label {
				padding-left: $form-choice-width + $form-padding-horizontal-small;
			}
		}
		&--basic {
			input {
				+ label {
					padding-left: $form-choice-width;
				}
			}
		}
		&--reverse {
			input {
				+ label {
					padding-left: 0;
					padding-right: $form-choice-width + $form-padding-horizontal-small;
				}
			}
		}
	}
	&.c-form-element--toggle {
		input {
			+ label {
				padding-left: $form-toggle-width + $form-padding-horizontal-small;
			}
		}
		&--basic {
			input {
				+ label {
					padding-left: $form-toggle-width;
				}
			}
		}
		&--reverse {
			input {
				+ label {
					padding-left: 0;
					padding-right: $form-toggle-width + $form-padding-horizontal-small;
				}
			}
		}
	}

	&.c-form-element--select {
		.c-form-element__field {
			&:before {
				right: $form-padding-horizontal-small;
				height: $form-field-height-small;
			}
			select {
				padding-right: $form-select-icon-size + $form-padding-horizontal-small + rem(4px);
			}
		}
		&.c-form-element--icon-last .c-form-element__icon,
		.c-form-element__icon:nth-child(2) {
			right: $form-padding-horizontal-small + $form-select-icon-size + $form-padding-horizontal-small + rem(4px);

			~ select,
			~ .fl-wrap select {
				padding-right: $form-padding-horizontal-small + $form-icon-size-small + $form-padding-horizontal-small + $form-select-icon-size + $form-padding-horizontal-small + rem(4px);
			}
		}
		select {
			padding-top: ($form-field-height-small - $form-text-line-height - (2 * $form-border-width)) / 2;
			padding-bottom: ($form-field-height-small - $form-text-line-height - (2 * $form-border-width)) / 2;
		}
	}
	[class*="c-form--float"] & {
		.c-form-element__field {
			.c-form-label {
				line-height: $form-field-height-small;
			}
			.fl-is-active {
				.c-form-label {
					line-height: 1;
				}
			}
		}
	}
	&.c-form-element--style-fill {
		@include input-text {
			padding-left: $form-padding-horizontal-small;
			padding-right: $form-padding-horizontal-small;
		}

		textarea {
			padding-top: ($form-field-height-small - (2 * $form-border-width) - ($form-text-size * $line-height-base)) / 2;
		}
		.c-form-element__icon {
			left: $form-padding-horizontal-small;

			~ input,
			~ select,
			~ textarea,
			~ .fl-wrap input,
			~ .fl-wrap select,
			~ .fl-wrap textarea {
				padding-left: $form-padding-horizontal-small + $form-icon-size + $form-padding-horizontal-small;
			}
		}
		&.c-form-element--icon-last .c-form-element__icon,
		.c-form-element__icon:nth-child(2) {
			left: auto;
			right: $form-padding-horizontal-small;

			~ input,
			~ textarea,
			~ .fl-wrap input,
			~ .fl-wrap textarea {
				padding-right: $form-padding-horizontal-small + $form-icon-size + $form-padding-horizontal-small;
			}
		}
		&.c-form-element--icon-last .c-form-element__icon {
			~ input,
			~ select,
			~ textarea,
			~ .fl-wrap input,
			~ .fl-wrap select,
			~ .fl-wrap textarea {
				padding-left: $form-padding-horizontal-small;
			}
		}
		&.c-form-element--select {
			&.c-form-element--icon-last .c-form-element__icon,
			.c-form-element__icon:nth-child(2) {
				right: $form-padding-horizontal-small + $form-select-icon-size + $form-padding-horizontal-small + rem(4px);

				~ select,
				~ .fl-wrap select {
					padding-right: $form-padding-horizontal-small + $form-icon-size + $form-padding-horizontal-small + $form-select-icon-size + $form-padding-horizontal-small + rem(4px);
				}
			}
		}
		[class*="c-form--float"] & {
			.c-form-element__field {
				.c-form-label {
					left: $form-padding-horizontal-small;
				}
				.fl-is-active {
					.c-form-label {
						left: 0;
					}
				}
			}
		}
	}
}
@mixin form-element--small {
	@include form-element--short;

	@include input-text {
		font-size: $form-text-size-small;
	}

	textarea {
		padding-top: ($form-field-height-small - ($form-text-size-small * $line-height-base)) / 2;
		padding-bottom: ($form-field-height-small - (2 * $form-border-width) - ($form-text-size-small * $line-height-base)) / 2;
	}
	.c-form-element__icon {
		width: $form-icon-size-small;
		font-size: $form-icon-size-small;

		img,
		svg {
			width: $form-icon-size-small;
			height: $form-icon-size-small;

			&.o-svg-large {
				height: $form-icon-size-small * 1.5;
				width: $form-icon-size-small * 1.5;
			}
			&.o-svg-larger {
				height: $form-icon-size-small * 1.25;
				width: $form-icon-size-small * 1.25;
			}
			&.o-svg-smaller {
				height: $form-icon-size-small * 0.75;
				width: $form-icon-size-small * 0.75;
			}
			&.o-svg-small {
				height: $form-icon-size-small * 0.5;
				width: $form-icon-size-small * 0.5;
			}
		}
		~ input,
		~ select,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap select,
		~ .fl-wrap textarea {
			padding-left: $form-icon-size-small + $form-padding-horizontal-small;
		}
	}
	&.c-form-element--icon-last .c-form-element__icon,
	.c-form-element__icon:nth-child(2) {
		left: auto;
		right: 0;

		~ input,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap textarea {
			padding-right: $form-icon-size-small + $form-padding-horizontal-small;
		}
	}
	&.c-form-element--icon-last .c-form-element__icon {
		~ input,
		~ select,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap select,
		~ .fl-wrap textarea {
			padding-left: 0;
		}
	}
	&.c-form-element--checkbox,
	&.c-form-element--checkbox-indeterminate,
	&.c-form-element--radio,
	&.c-form-element--toggle {
		input {
			+ label {
				padding-left: ($form-choice-width - rem(2px)) + $form-padding-horizontal-small;
				min-height: $form-text-size-small * $line-height-base;
				font-size: $form-text-size-small;

				&:before {
					top: (($form-text-size-small * $line-height-base) - ($form-choice-width - rem(2px))) / 2;
					width: $form-choice-width - rem(2px);
					height: $form-choice-width - rem(2px);
				}
				&:after {
					top: (($form-text-size-small * $line-height-base)) / 2;
					left: ($form-choice-width - rem(2px)) / 2;
					width: $form-radio-dot-size - rem(2px);
					height: $form-radio-dot-size - rem(2px);
				}
			}
		}
		&--basic {
			input {
				+ label {
					padding-left: $form-choice-width - rem(2px);
				}
			}
		}
		&--reverse {
			input {
				+ label {
					padding-left: 0;
					padding-right: ($form-choice-width - rem(2px)) + $form-padding-horizontal-small;

					&:after {
						left: auto;
						right: ($form-choice-width - rem(2px)) / 2;
					}
				}
			}
		}
	}
	&.c-form-element--toggle {
		input {
			+ label {
				padding-left: ($form-toggle-width - rem(2px)) + $form-padding-horizontal-small;

				&:before {
					width: $form-toggle-width - rem(2px);
					border-radius: ($form-choice-width - rem(2px)) / 2;
				}
				&:after {
					left: ($form-choice-width - rem(2px)) / 2;
					width: $form-toggle-dot-size - rem(2px);
					height: $form-toggle-dot-size - rem(2px);
				}
			}
			&:checked {
				+ label {
					&:after {
						left: ($form-toggle-width - rem(2px)) - (($form-choice-width - rem(2px)) / 2);
					}
				}
			}
		}
		&--basic {
			input {
				+ label {
					padding-left: $form-toggle-width - rem(2px);
				}
			}
		}
		&--reverse {
			input {
				+ label {
					padding-left: 0;
					padding-right: ($form-toggle-width - rem(2px)) + $form-padding-horizontal-small;

					&:after {
						left: auto;
						right: ($form-toggle-width - rem(2px)) - (($form-choice-width - rem(2px)) / 2);
					}
				}
				&:checked {
					+ label {
						&:after {
							left: auto;
							right: ($form-choice-width - rem(2px)) / 2;
						}
					}
				}
			}
		}
	}
	[class*="c-form--float"] & {
		.c-form-element__field {
			.c-form-label {
				font-size: $form-text-size-small;
			}
			.fl-is-active {
				.c-form-label {
					font-size: $form-label-size;
				}
			}
		}
	}
	&.c-form-element--style-fill {
		textarea {
			padding-top: ($form-field-height-small - (2 * $form-border-width) - ($form-text-size-small * $line-height-base)) / 2;
		}
		.c-form-element__icon {
			~ input,
			~ select,
			~ textarea,
			~ .fl-wrap input,
			~ .fl-wrap select,
			~ .fl-wrap textarea {
				padding-left: $form-padding-horizontal-small + $form-icon-size-small + $form-padding-horizontal-small;
			}
		}
		&.c-form-element--icon-last .c-form-element__icon,
		.c-form-element__icon:nth-child(2) {
			~ input,
			~ textarea,
			~ .fl-wrap input,
			~ .fl-wrap textarea {
				padding-right: $form-padding-horizontal-small + $form-icon-size-small + $form-padding-horizontal-small;
			}
		}
		&.c-form-element--icon-last .c-form-element__icon {
			~ input,
			~ select,
			~ textarea,
			~ .fl-wrap input,
			~ .fl-wrap select,
			~ .fl-wrap textarea {
				padding-left: $form-padding-horizontal-small;
			}
		}
		&.c-form-element--select {
			&.c-form-element--icon-last .c-form-element__icon,
			.c-form-element__icon:nth-child(2) {
				~ select,
				~ .fl-wrap select {
					padding-right: $form-padding-horizontal-small + $form-icon-size-small + $form-padding-horizontal-small + $form-select-icon-size + $form-padding-horizontal-small + rem(4px);
				}
			}
		}
	}
}
@mixin form-element--high {
	@include input-text {
		min-height: $form-field-height-large;
		max-height: $form-field-height-large;
		padding-top: ($form-field-height-large - $form-text-line-height - (2 * $form-border-width)) / 2;
		padding-bottom: ($form-field-height-large - $form-text-line-height - (2 * $form-border-width)) / 2;
	}
	select {
		&[multiple] {
			height: $form-textarea-height-large;
		}
	}
	textarea {
		min-height: inherit;
		max-height: none;
		height: $form-textarea-height-large;
		padding-top: ($form-field-height-large - ($form-text-size * $line-height-base)) / 2;
		padding-bottom: ($form-field-height-large - (2 * $form-border-width) - ($form-text-size * $line-height-base)) / 2;
	}
	.c-form-element__icon {
		height: $form-field-height-large;

		~ input,
		~ select,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap select,
		~ .fl-wrap textarea {
			padding-left: $form-icon-size + $form-padding-horizontal-large;
		}
	}
	&.c-form-element--icon-last .c-form-element__icon,
	.c-form-element__icon:nth-child(2) {
		~ input,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap textarea {
			padding-right: $form-icon-size + $form-padding-horizontal-large;
		}
	}
	&.c-form-element--icon-last .c-form-element__icon {
		~ input,
		~ select,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap select,
		~ .fl-wrap textarea {
			padding-left: 0;
		}
	}
	&.c-form-element--checkbox,
	&.c-form-element--checkbox-indeterminate,
	&.c-form-element--radio,
	&.c-form-element--toggle {
		input {
			+ label {
				padding-left: $form-choice-width + $form-padding-horizontal-large;
			}
		}
		&--basic {
			input {
				+ label {
					padding-left: $form-choice-width;
				}
			}
		}
		&--reverse {
			input {
				+ label {
					padding-left: 0;
					padding-right: $form-choice-width + $form-padding-horizontal-large;
				}
			}
		}
	}
	&.c-form-element--toggle {
		input {
			+ label {
				padding-left: $form-toggle-width + $form-padding-horizontal-large;
			}
		}
		&--basic {
			input {
				+ label {
					padding-left: $form-toggle-width;
				}
			}
		}
		&--reverse {
			input {
				+ label {
					padding-left: 0;
					padding-right: $form-toggle-width + $form-padding-horizontal-large;
				}
			}
		}
	}
	&.c-form-element--select {
		.c-form-element__field {
			&:before {
				right: $form-padding-horizontal-large;
				height: $form-field-height-large;
			}
			select {
				padding-right: $form-select-icon-size + $form-padding-horizontal-large + rem(4px);
			}
		}
		&.c-form-element--icon-last .c-form-element__icon,
		.c-form-element__icon:nth-child(2) {
			right: $form-padding-horizontal-large + $form-select-icon-size + $form-padding-horizontal-large + rem(4px);

			~ select,
			~ .fl-wrap select {
				padding-right: $form-padding-horizontal-large + $form-icon-size + $form-padding-horizontal-large + $form-select-icon-size + $form-padding-horizontal-large + rem(4px);
			}
		}
		select {
			padding-top: ($form-field-height-large - $form-text-line-height - (2 * $form-border-width)) / 2;
			padding-bottom: ($form-field-height-large - $form-text-line-height - (2 * $form-border-width)) / 2;
		}
	}
	[class*="c-form--float"] & {
		.c-form-element__field {
			.c-form-label {
				line-height: $form-field-height-large;
			}
			.fl-is-active {
				.c-form-label {
					line-height: 1;
				}
			}
		}
	}
	&.c-form-element--style-fill {
		@include input-text {
			padding-left: $form-padding-horizontal-large;
			padding-right: $form-padding-horizontal-large;
		}

		textarea {
			padding-top: ($form-field-height-large - (2 * $form-border-width) - ($form-text-size * $line-height-base)) / 2;
		}
		.c-form-element__icon {
			left: $form-padding-horizontal-large;

			~ input,
			~ select,
			~ textarea,
			~ .fl-wrap input,
			~ .fl-wrap select,
			~ .fl-wrap textarea {
				padding-left: $form-padding-horizontal-large + $form-icon-size + $form-padding-horizontal-large;
			}
		}
		&.c-form-element--icon-last .c-form-element__icon,
		.c-form-element__icon:nth-child(2) {
			left: auto;
			right: $form-padding-horizontal-large;

			~ input,
			~ textarea,
			~ .fl-wrap input,
			~ .fl-wrap textarea {
				padding-right: $form-padding-horizontal-large + $form-icon-size + $form-padding-horizontal-large;
			}
		}
		&.c-form-element--icon-last .c-form-element__icon {
			~ input,
			~ select,
			~ textarea,
			~ .fl-wrap input,
			~ .fl-wrap select,
			~ .fl-wrap textarea {
				padding-left: $form-padding-horizontal-large;
			}
		}
		&.c-form-element--select {
			&.c-form-element--icon-last .c-form-element__icon,
			.c-form-element__icon:nth-child(2) {
				right: $form-padding-horizontal-large + $form-select-icon-size + $form-padding-horizontal-large + rem(4px);

				~ select,
				~ .fl-wrap select {
					padding-right: $form-padding-horizontal-large + $form-icon-size + $form-padding-horizontal-large + $form-select-icon-size + $form-padding-horizontal-large + rem(4px);
				}
			}
		}
		[class*="c-form--float"] & {
			.c-form-element__field {
				.c-form-label {
					left: $form-padding-horizontal-large;
				}
				.fl-is-active {
					.c-form-label {
						left: 0;
					}
				}
			}
		}
	}
}
@mixin form-element--large {
	@include form-element--high;

	@include input-text {
		font-size: $form-text-size-large;
	}

	textarea {
		padding-top: ($form-field-height-large - ($form-text-size-large * $line-height-base)) / 2;
		padding-bottom: ($form-field-height-large - (2 * $form-border-width) - ($form-text-size-large * $line-height-base)) / 2;
	}
	.c-form-element__icon {
		width: $form-icon-size-large;
		font-size: $form-icon-size-large;

		img,
		svg {
			width: $form-icon-size-large;
			height: $form-icon-size-large;

			&.o-svg-large {
				height: $form-icon-size-large * 1.5;
				width: $form-icon-size-large * 1.5;
			}
			&.o-svg-larger {
				height: $form-icon-size-large * 1.25;
				width: $form-icon-size-large * 1.25;
			}
			&.o-svg-smaller {
				height: $form-icon-size-large * 0.75;
				width: $form-icon-size-large * 0.75;
			}
			&.o-svg-small {
				height: $form-icon-size-large * 0.5;
				width: $form-icon-size-large * 0.5;
			}
		}
		~ input,
		~ select,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap select,
		~ .fl-wrap textarea {
			padding-left: $form-icon-size-large + $form-padding-horizontal-large;
		}
	}
	&.c-form-element--icon-last .c-form-element__icon,
	.c-form-element__icon:nth-child(2) {
		left: auto;
		right: 0;

		~ input,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap textarea {
			padding-right: $form-icon-size-large + $form-padding-horizontal-large;
		}
	}
	&.c-form-element--icon-last .c-form-element__icon {
		~ input,
		~ select,
		~ textarea,
		~ .fl-wrap input,
		~ .fl-wrap select,
		~ .fl-wrap textarea {
			padding-left: 0;
		}
	}
	&.c-form-element--checkbox,
	&.c-form-element--checkbox-indeterminate,
	&.c-form-element--radio,
	&.c-form-element--toggle {
		input {
			+ label {
				padding-left: ($form-choice-width + rem(2px)) + $form-padding-horizontal-large;
				min-height: $form-text-size-large * $line-height-base;
				font-size: $form-text-size-large;

				&:before {
					top: (($form-text-size-large * $line-height-base) - ($form-choice-width + rem(2px))) / 2;
					width: $form-choice-width + rem(2px);
					height: $form-choice-width + rem(2px);
				}
				&:after {
					top: (($form-text-size-large * $line-height-base)) / 2;
					left: ($form-choice-width + rem(2px)) / 2;
					width: $form-radio-dot-size + rem(2px);
					height: $form-radio-dot-size + rem(2px);
				}
			}
		}
		&--basic {
			input {
				+ label {
					padding-left: $form-choice-width + rem(2px);
				}
			}
		}
		&--reverse {
			input {
				+ label {
					padding-left: 0;
					padding-right: ($form-choice-width + rem(2px)) + $form-padding-horizontal-large;

					&:after {
						left: auto;
						right: ($form-choice-width + rem(2px)) / 2;
					}
				}
			}
		}
	}
	&.c-form-element--toggle {
		input {
			+ label {
				padding-left: ($form-toggle-width + rem(2px)) + $form-padding-horizontal-large;

				&:before {
					width: $form-toggle-width + rem(2px);
					border-radius: ($form-choice-width + rem(2px)) / 2;
				}
				&:after {
					left: ($form-choice-width + rem(2px)) / 2;
					width: $form-toggle-dot-size + rem(2px);
					height: $form-toggle-dot-size + rem(2px);
				}
			}
			&:checked {
				+ label {
					&:after {
						left: ($form-toggle-width + rem(2px)) - (($form-choice-width + rem(2px)) / 2);
					}
				}
			}
		}
		&--basic {
			input {
				+ label {
					padding-left: $form-toggle-width + rem(2px);
				}
			}
		}
		&--reverse {
			input {
				+ label {
					padding-left: 0;
					padding-right: ($form-toggle-width + rem(2px)) + $form-padding-horizontal-large;

					&:after {
						left: auto;
						right: ($form-toggle-width + rem(2px)) - (($form-choice-width + rem(2px)) / 2);
					}
				}
				&:checked {
					+ label {
						&:after {
							left: auto;
							right: ($form-choice-width + rem(2px)) / 2;
						}
					}
				}
			}
		}
	}
	&.c-form-element--select {
		&.c-form-element--icon-last .c-form-element__icon,
		.c-form-element__icon:nth-child(2) {
			~ select,
			~ .fl-wrap select {
				padding-right: $form-padding-horizontal-large + $form-icon-size-large + $form-padding-horizontal-large + $form-select-icon-size + $form-padding-horizontal-large + rem(4px);
			}
		}
	}
	[class*="c-form--float"] & {
		.c-form-element__field {
			.c-form-label {
				font-size: $form-text-size-large;
			}
			.fl-is-active {
				.c-form-label {
					font-size: $form-label-size;
				}
			}
		}
	}
	&.c-form-element--style-fill {
		textarea {
			padding-top: ($form-field-height-large - (2 * $form-border-width) - ($form-text-size-large * $line-height-base)) / 2;
		}
		.c-form-element__icon {
			~ input,
			~ select,
			~ textarea,
			~ .fl-wrap input,
			~ .fl-wrap select,
			~ .fl-wrap textarea {
				padding-left: $form-padding-horizontal-large + $form-icon-size-large + $form-padding-horizontal-large;
			}
		}
		&.c-form-element--icon-last .c-form-element__icon,
		.c-form-element__icon:nth-child(2) {
			~ input,
			~ textarea,
			~ .fl-wrap input,
			~ .fl-wrap textarea {
				padding-right: $form-padding-horizontal-large + $form-icon-size-large + $form-padding-horizontal-large;
			}
		}
		&.c-form-element--select {
			&.c-form-element--icon-last .c-form-element__icon,
			.c-form-element__icon:nth-child(2) {
				~ select,
				~ .fl-wrap select {
					padding-right: $form-padding-horizontal-large + $form-icon-size-large + $form-padding-horizontal-large + $form-select-icon-size + $form-padding-horizontal-large + rem(4px);
				}
			}
		}
	}
}
@mixin form-element--extra-large {
	@include input-text {
		min-height: $form-field-height-extra-large;
		max-height: $form-field-height-extra-large;
		padding-top: ($form-field-height-extra-large - $form-text-line-height-extra-large - (2 * $form-border-width)) / 2;
		padding-bottom: ($form-field-height-extra-large - $form-text-line-height-extra-large - (2 * $form-border-width)) / 2;
		line-height: $form-text-line-height-extra-large;
		font-size: $form-text-size-extra-large;
	}
}

.c-form-element--short {
	@include form-element--short;
}
.c-form-element--small {
	@include form-element--small;
}
.c-form-element--high {
	@include form-element--high;
}
.c-form-element--large {
	@include form-element--large;
}
.c-form-element--extra-large {
	@include form-element--extra-large;
}



/* Form with floating labels
   ========================================================================== */

[class*="c-form--float"] {
	.c-form-element__field {
		.c-form-label {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			margin: 0;
			font-size: $form-text-size;
			line-height: $form-field-height;
			color: $form-label-color;
			transition: all $transition-speed ease-in;
			pointer-events: none;
			white-space: nowrap;
			overflow: hidden;

			.c-color--invert & {
				color: $form-label-color-invert;
			}
		}
		.fl-has-focus {
			.c-form-label {
				color: $form-label-color;

				.c-color--invert & {
					color: $form-label-color-invert;
				}
			}
		}
		.fl-is-active {
			.c-form-label {
				top: - $form-label-size;
				font-size: $form-label-size;
				color: $form-label-color;
				line-height: 1;
				transform: none;

				.c-color--invert & {
					color: $form-label-color-invert;
				}
			}
			&.fl-has-focus {
				.c-form-label {
					color: $form-label-color-focus;

					.c-color--invert & {
						color: $form-label-color-invert-focus;
					}
				}
			}
		}
	}
	.c-form-element--select {
		.c-form-element__field {
			.c-form-label {
				opacity: 0;
				visibility: hidden;
			}
			.fl-is-active {
				.c-form-label {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}

	.c-form-element--style-line {
		.c-form-element__field {
			.c-form-label {
				color: $form-placeholder-color;

				.c-color--invert & {
					color: $form-placeholder-color-invert;
				}
			}
			.fl-has-focus {
				.c-form-label {
					color: $form-placeholder-color;

					.c-color--invert & {
						color: $form-placeholder-color-invert;
					}
				}
			}
			.fl-is-active {
				.c-form-label {
					color: $form-label-color;

					.c-color--invert & {
						color: $form-label-color-invert;
					}
				}
				&.fl-has-focus {
					.c-form-label {
						color: $form-label-color-focus;

						.c-color--invert & {
							color: $form-label-color-invert-focus;
						}
					}
				}
			}
		}
	}
	.c-form-element--style-fill {
		.c-form-element__field {
			.c-form-label {
				left: $form-padding-horizontal;
				color: $form-fill-placeholder-color;

				.c-color--invert & {
					color: $form-fill-placeholder-color-invert;
				}
			}
			.fl-has-focus {
				.c-form-label {
					color: $form-fill-placeholder-color;

					.c-color--invert & {
						color: $form-fill-placeholder-color-invert;
					}
				}
			}
			.fl-is-active {
				.c-form-label {
					left: 0;
					top: - $form-label-size - $spacer;
					color: $form-label-color;

					.c-color--invert & {
						color: $form-label-color-invert;
					}
				}
				&.fl-has-focus {
					.c-form-label {
						color: $form-label-color-focus;

						.c-color--invert & {
							color: $form-label-color-invert-focus;
						}
					}
				}
			}
		}
	}
}
.c-form--float-labels {
	.c-form-element__field {
		.c-form-label {
			opacity: 0;
			visibility: hidden;
		}
		.fl-is-active {
			.c-form-label {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}




/* Form validations
   ========================================================================== */

.c-form-element--error {
	.c-form-element--error__list {
		display: block;
	}

	&.c-form-element--style-line {
		@include input-text {
			background-color: $form-background-error;
			border-color: $form-border-color-error;

			&:focus,
			&:active {
				box-shadow: $form-shadow-error;
			}

			.c-color--invert & {
				background-color: $form-background-invert-error;
				border-color: $form-border-color-invert-error;

				&:focus,
				&:active {
					box-shadow: $form-shadow-invert-error;
				}
			}
		}
	}
	&.c-form-element--style-fill {
		@include input-text {
			background-color: $form-fill-background-error;
			border-color: $form-fill-border-color-error;

			&:focus,
			&:active {
				box-shadow: $form-fill-shadow-error;
			}

			.c-color--invert & {
				background-color: $form-fill-background-invert-error;
				border-color: $form-fill-border-color-invert-error;

				&:focus,
				&:active {
					box-shadow: $form-fill-shadow-invert-error;
				}
			}
		}
	}
}
.c-form-element--error__list {
	display: none;
	margin-bottom: $spacer;
	text-align: left;

	li {
		font-size: $form-help-text-size;
		color: color(extra,error);
		font-weight: $form-help-weight;
	}
	p {
		margin-bottom: $spacer;
		font-size: $form-help-text-size;
		color: color(extra,error);
		font-weight: $form-help-weight;
	}
}

.c-form__error-notification {
	margin-bottom: $spacer-base-large;
	padding-top: $spacer-base-small + rem(1px);
	padding-left: $spacer-base-small;
	padding-right: $spacer-base-small;
	padding-bottom: rem(1px);
	color: #fff;
	text-align: left;
	background: color(extra, error);
	border-radius: $form-field-border-radius;

	> * {
		margin-bottom: $spacer-base-small;
	}
}

.c-form__confirmation {
	position: relative;
	padding-top: $spacer-base-large + rem(50px);
    padding-bottom: $spacer-base-large - $spacer-base;
    padding-left: $spacer-base;
    padding-right: $spacer-base;
    display: block;
	text-align: center;
    color: color(primary);

	&:before {
		content: '';
		position: absolute;
		top: $spacer-base;
		left: 50%;
		transform: translate(-50%, 0);
		width: rem(50px);
		height: rem(50px);
		border-radius: 50%;
		border: 1px solid color(primary);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-image: $form-check-icon-path-invert;
		background-size: rem(24px);
	}
}




/* Other
   ========================================================================== */

.c-form__footer {
	margin-bottom: $spacer-base;
}

.c-image-edit {
	align-items: center;
	margin-bottom: $spacer-base-small;

	.c-image-edit__container {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: rem(300px);
		height: rem(100px);
		margin-left: auto;
		margin-right: auto;
	}
	img {
		display: block;
		max-height: 100%;
		margin-bottom: $spacer-base-small;
		margin-top: $spacer-base-small;

		&[src$=".svg"] {
			width: 100%;
			height: 100%;
		}
	}
	.c-button-group {
		margin-top: 0;
		margin-bottom: 0;
	}
	p {
		margin-bottom: $spacer-base-small;
		margin-top: $spacer-base-small;
	}
}
